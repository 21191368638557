.footer {
    background-color: #000;
    padding: 15px 0;
    width: 100%;
    font-size: 0.8rem;
    color: rgb(167, 170, 170);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.footer a {
    color: inherit;
    text-decoration: none;
}

.footer span {
    margin: 5px 0;
}